import { useTrackJoinedAWorkspace } from '@air/analytics';
import { Workspaces } from '@air/api';
import { matchesAirror, OVER_MAX_MEMBERS } from '@air/errors';
import Router from 'next/router';
import { useCallback } from 'react';

import { Routes } from '~/constants/routes';
import { useAddMemberByInviteToken } from '~/swr-hooks/members/useAddMemberByInviteToken';

import { InviteType, JoinInfo } from './useJoinWorkspaceInfo';

export interface HandleJoinWorkspaceInfoParams {
  workspaceInfo: JoinInfo;
  onSuccess?: () => void;
  email: string;
}

export const useHandleJoinWorkspaceInfo = () => {
  const { addMemberByInviteToken } = useAddMemberByInviteToken();
  const { trackJoinedAWorkspace } = useTrackJoinedAWorkspace();

  const handleJoinWorkspaceInfo = useCallback(
    async ({ workspaceInfo, onSuccess, email }: HandleJoinWorkspaceInfoParams) => {
      if (workspaceInfo.inviteType === InviteType.link) {
        try {
          await addMemberByInviteToken({ userEmail: email, tokenValue: workspaceInfo.shortId });
        } catch (error) {
          if (matchesAirror(error, OVER_MAX_MEMBERS)) {
            return Router.push(Routes.workspaceMembersLimit);
          } else {
            throw error;
          }
        }
      }

      trackJoinedAWorkspace({
        method: workspaceInfo.inviteType === InviteType.link ? 'link' : 'email',
        workspaceName: workspaceInfo.name,
        workspaceId: workspaceInfo.id,
      });

      await Workspaces.switchWorkspaces({
        workspaceId: workspaceInfo.id,
      });

      onSuccess?.();
    },
    [addMemberByInviteToken, trackJoinedAWorkspace],
  );

  return {
    handleJoinWorkspaceInfo,
  };
};
