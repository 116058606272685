import { config, Workspaces } from '@air/api';
import { useQueryClient } from '@tanstack/react-query';
import Router from 'next/router';
import { useCallback } from 'react';

import { useHandleJoinWorkspaceInfo } from '~/components/JoinWorkspace/hooks/useHandleJoinWorkspaceInfo';
import { useJoinWorkspaceInfo } from '~/components/JoinWorkspace/hooks/useJoinWorkspaceInfo';
import { useHandleNoWorkspaces } from '~/components/Layouts/HomeLayout/hooks/useHandleNoWorkspaces';
import { Routes } from '~/constants/routes';
import { getWorkspacesListKey } from '~/swr-hooks/workspaces/useWorkspaces';
import { wipeCognitoArtifactsExceptFor } from '~/utils/wipePersistedCognitoArtifacts';

export interface HandlePostAuthenticationParams {
  email: string;
  redirect: string | undefined;
}

/**
 * This hook should be used for handling a user after they sign up/login and they don't have a
 * URL to redirect to.
 *
 * @see https://whimsical.com/frontend-auth-flow-7aKmdVftGHck9GRSGeV8mN
 */
export const useHandlePostAuthentication = () => {
  const { getJoinWorkspaceInfo } = useJoinWorkspaceInfo();
  const { handleJoinWorkspaceInfo } = useHandleJoinWorkspaceInfo();
  const queryClient = useQueryClient();
  const { handleNoWorkspaces } = useHandleNoWorkspaces();

  const handlePostAuthentication = useCallback(
    async ({ email, redirect = Routes.home }: HandlePostAuthenticationParams) => {
      const workspaceInfo = getJoinWorkspaceInfo();

      if (workspaceInfo) {
        return handleJoinWorkspaceInfo({
          workspaceInfo,
          email,
          onSuccess: () => Router.push(redirect),
        });
      }

      const workspaces = await queryClient.fetchQuery({
        queryKey: getWorkspacesListKey(),
        queryFn: () => Workspaces.list(),
      });

      /**
       * User can be logged in with anonymous account on public page.
       * If they log in with full account, we need to wipe anonymous account data from local storage.
       */
      const authConfig = config.getAuthConfiguration();
      wipeCognitoArtifactsExceptFor(authConfig.oauth.clientId);

      /**
       * If they have workspaces they've joined (most likely the case), send them to the latest one
       */
      if (workspaces.length) {
        return Router.push(redirect);
      }

      return handleNoWorkspaces(redirect);
    },
    [getJoinWorkspaceInfo, handleJoinWorkspaceInfo, handleNoWorkspaces, queryClient],
  );

  return {
    handlePostAuthentication,
  };
};
