import { useTrackHideIntercomWidget, useTrackShowIntercomWidget } from '@air/analytics';
import { useEffect } from 'react';

/** Use this hook to show Intercom on mount and hide in on unmount */
export const useShowIntercomOnMount = (enabled = true) => {
  const { trackShowIntercomWidget } = useTrackShowIntercomWidget();
  const { trackHideIntercomWidget } = useTrackHideIntercomWidget();

  useEffect(() => {
    if (enabled) {
      trackShowIntercomWidget();

      return () => {
        trackHideIntercomWidget();
      };
    }
  }, [trackHideIntercomWidget, trackShowIntercomWidget, enabled]);
};
