import Cookies from 'js-cookie';

const wipeAllLocalData = (filterDataFn: (key: string) => boolean) => {
  if (typeof window !== 'undefined') {
    const allCookies = Cookies.get();
    Object.keys(allCookies)
      // get all the Cogntio-related keys
      .filter(filterDataFn)
      // and remove them
      .forEach((key) => Cookies.remove(key));

    Object.keys(localStorage)
      // get all the Cogntio-related keys
      .filter(filterDataFn)
      // and remove them
      .forEach((key) => localStorage.removeItem(key));
  }
};

/**
 * We have an issue where sometimes Cognito doesn't seem to wipe all of the cookies + local storage keys associated
 * with a user session when they logout. Thus, when they try to login later with a different email address with
 * SSO, they get stuck in a logout loop.
 * @see https://air-labs-team.slack.com/archives/CKE8SK3M1/p1623433794039900
 *
 * @param authClientId - the client id to wipe artifacts for.
 *  This parameter is added to avoid clearing anonymous account artifacts when user open 'login' page.
 *  Anonymous account artifacts are stored in a different client id.
 */
export const wipePersistedCognitoArtifacts = (authClientId: string) => {
  wipeAllLocalData((key) => key.startsWith(`CognitoIdentityServiceProvider.${authClientId}`));
};

export const wipeCognitoArtifactsExceptFor = (authClientId: string) => {
  wipeAllLocalData(
    (key) =>
      key.startsWith(`CognitoIdentityServiceProvider`) &&
      !key.startsWith(`CognitoIdentityServiceProvider.${authClientId}`),
  );
};
